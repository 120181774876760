<template>
  <div class="delete-geofence-sidebar">
    <div style="display: flex; align-items: center; padding: 40px">
      <CRIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        color="red"
        style="flex-shrink: 0"
      >
        alert_outline
      </CRIcon>
      <p style="color: #e10055; margin: 0; padding-left: 10px">
        Delete area of interest '{{ name }}'?
      </p>
    </div>
    <v-spacer />
    <v-btn
      id="delete-geofence-save-button"
      class="delete-geofence-save-button"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="onDeleteGeofenceArea"
    >
      Delete Area of Interest
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { deleteGeofenceById } from '@/services/geofences'

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    geoFenceCoordinates: {
      type: Array,
      default: () => [],
    },
    geoFenceType: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapActions({ closeDialog: 'app/closeDialog' }),
    deleteGeofenceById,
    async onDeleteGeofenceArea() {
      await this.deleteGeofenceById(this.id)
      EventBus.$emit('geofence-refresh')
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-geofence-sidebar {
  height: 100%;
  width: 500px;
}

.delete-geofence-save-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: inherit;
  height: 71px;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
</style>
